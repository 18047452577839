const winda = require(".././assets/winda.jpg");
const argent = require(".././assets/argent.jpg");
const salford = require(".././assets/salford.jpg");
const h2s = require(".././assets/h2s.jpg");
const eeh = require(".././assets/eeh.jpg");
const wft = require(".././assets/water.jpg");
const adept = require(".././assets/adept.jpg");

export const cases = [
  {
    imgUrl: wft,
    title: "Water Freight Tool",
    description: "An online tool to get construction materials moving by water",
    client: "Transport for London",
    to: "/water-freight-tool",
    sector: "Raising Standards",
    content: [
      {
        title: "Who is the client?",
        copy:
          "Transport for London (TfL) is the capital’s integrated transport authority. The Canal & River Trust and the Port of London Authority assisted TfL on this project, which supports the Mayor of London’s strategy to increase use of the River Thames for transport."
      },
      {
        title: "What was their challenge?",
        copy:
          "Before this project there was little awareness in the construction industry about water freight’s potential. Although there are over 40 wharves in London equipped to store different types of construction materials, until now there was no centralised source of information about them."
      },
      {
        title: "How did we solve it?",
        copy:
          "We started by engaging with different stakeholders, ranging from planning bodies to construction contractors, to ensure we understood their needs. Then we built a database of wharves and landing areas. We used this to create an interactive map, using our own Project View GIS software, that shows the user all wharves within a 20-minute HGV drive time of any location they select, and enables them to see full details of any wharf."
      },
      {
        title: "What was the outcome?",
        copy:
          "Our work has benefitted the health and wellbeing of Londoners, as well as reducing costs for the construction industry. Engaging thoroughly with stakeholders – we shared visual mock-ups regularly and presented at two day-long construction industry workshops – paid off: around 20 users every month access the tool to investigate their construction logistics opportunities on the river."
      }
    ]
  },
  {
    imgUrl: argent,
    title: "Argent Spatial Data",
    description: "Making spatial data handling more efficient and more robust",
    client: "Argent",
    sector: "Transport and planning",
    to: "/argent-spatial",
    content: [
      {
        title: "Who is the client?",
        copy:
          "Property developer Argent is responsible for major urban regeneration and development projects, including King’s Cross in London. Since 2014, Argent’s information management team has benefitted from our spatial data consultancy and technical support services."
      },
      {
        title: "What was their challenge?",
        copy:
          "Argent uses a web-based geographic information system (GIS) to manage, distribute and visualise spatial data across the lifecycle of its development projects. This includes plot build status, site infrastructure, public realm, utilities, topographical surveys and construction logistics. Because the data is continuously updated, robust data standards and management procedures are crucial for ensuring consistency, integrity and efficiency in data handling."
      },
      {
        title: "How did we solve it?",
        copy:
          "We advised on the internal spatial data structure and management standards as well as designing, developing and implementing bespoke tools to more robustly manage spatial data. This included working with Argent’s internal information management team and key external stakeholders to better define their data structure and helping implement naming standards for GIS data and documents. We also advised how to better manage the flow of data between stakeholders and data formats – and developed custom workflows and tools to enable this."
      },
      {
        title: "What was the outcome?",
        copy:
          "The tools and data handling processes we implemented have resulted in substantial efficiency savings and more robust management, quality assurance, interoperability and dissemination of all spatial data associated with Argent’s projects. This is helping the developer deliver regeneration that is transforming areas such as King’s Cross."
      }
    ]
  },
  {
    imgUrl: salford,
    title: "University of Salford",
    description:
      "Harnessing digital technology to benefit a university and a city",
    client: "The University of Salford",
    sector: "Understanding Impact",
    to: "/salford-uni",
    content: [
      {
        title: "Who is the client?",
        copy:
          "With 7 schools, 20,000 students and 2,500 staff, the University of Salford is at the heart of the city and has ambitious plans to redevelop its campus over the next 20 years. Salford City Council supports the university’s masterplan."
      },
      {
        title: "What was their challenge?",
        copy:
          "The university and council wished to use the growth and opportunity offered by the demand for higher education, research and development, and innovation within the campus to benefit the communities of the university and wider city. The resulting masterplan extends to nearly 100ha close to the centre of Salford and focuses on job creation, engaging and involving the community, fostering more start-up businesses and providing a cultural hub."
      },
      {
        title: "How did we solve it?",
        copy:
          "We worked with stakeholders within the university, council and MediaCityUK development to understand the constraints and the huge opportunities for students, staff and the surrounding community that could be provided by embracing digital technologies and leveraging existing skills, capabilities and infrastructure. Through a series of workshops, we created a compelling digital vision and a wide-ranging set of recommendations – covering mobility, digital infrastructure and spaces – for the masterplan, which was agreed in spring 2018."
      },
      {
        title: "What was the outcome?",
        copy:
          "As part of the masterplan, which will create an £800 million investment opportunity for the city and its people, the university will move towards a virtual learning environment incorporating virtual, immersive and augmented reality technology in ways that will bring real benefits to its students and staff. It has already begun trialling a driverless shuttle bus on its campus – a tangible example of how it’s looking to use technology to improve quality of life."
      }
    ]
  },
  {
    imgUrl: h2s,
    title: "Highspeed Rail 2",
    description: "Making ecological surveys more efficient",
    client: "HS2",
    sector: "Creating Efficiency",
    to: "/highspeed-rail",
    content: [
      {
        title: "Who is the client?",
        copy:
          "HS2 is the company building High Speed Two – the new railway that will be the backbone of Britain’s transport network. It’s one of the most demanding and exciting transport projects in Europe."
      },
      {
        title: "What was their challenge?",
        copy:
          "Understanding a major project’s impact on the ecology of an area requires information about existing habitats to be collected in a structured way. HS2 needed to assess the habitats along the proposed Phase 2B route as part of its environmental assessment. With a commitment to delivering value for taxpayers’ money it also wanted to find an innovative way to do it."
      },
      {
        title: "How did we solve it?",
        copy:
          "We harnessed the power of algorithms, data from advanced imaging techniques, and GIS to make the process of identifying habitat more efficient. Instead of starting from scratch, ecologists could take mobile devices out in the field and either verify the habitat information already mapped in the system or quickly update it if necessary. By creating guidance documents and running workshops for all the consultancies working on Phase 2B, we ensured a consistent, efficient approach across the project."
      },
      {
        title: "What was the outcome?",
        copy:
          "Our innovative approach to identifying provisional ecological habitats across the HS2 route provided significant time and cost savings. By reducing the natural variation that occurs when different ecologists interpret habitats from scratch, we have also helped to create a more consistent picture of habitats for HS2 to use as it seeks to preserve overall levels of ecology along the route."
      }
    ]
  },
  {
    imgUrl: eeh,
    title: "Englands Economic Heartland",
    description:
      "Mapping constraints and opportunities for transport infrastructure",
    client: "Englands Economic Heartland",
    sector: "Focusing Collaboration",
    to: "/economic-case",
    content: [
      {
        title: "Who is the client?",
        copy:
          "Stretching from Swindon to Cambridgeshire and from Northamptonshire to Hertfordshire, England’s Economic Heartland brings political and business leaders together to enable the human and economic potential of the Oxford-Milton Keynes-Cambridge corridor and surrounding areas."
      },
      {
        title: "What was their challenge?",
        copy:
          "The people of the rapidly expanding Heartland require an additional 1 million homes and more than 400,000 jobs by 2051. To realise its full human and economic potential, the area needs strategic improvements to its transport network – including improving east-west connectivity and making it easy for people to travel the ‘last mile’ into urban centres."
      },
      {
        title: "How did we solve it?",
        copy:
          "To identify existing and potential constraints and opportunities for transport infrastructure, we produced a robust evidence base. This covers current baselines, transport networks and operation, travel demand and patterns, current and planned growth, and forecast travel demand. Then, by implementing a web mapping application using our ProjectView platform, we gave stakeholders a clear and simple way to access and visualise the rich and complex data."
      },
      {
        title: "What was the outcome?",
        copy:
          "ProjectView is now available for 20 different organisations to access. It will be a core tool for engaging local authorities, transport providers and the private sector in the debate that will shape a transport strategy to benefit the estimated 1.6 million people who will live in the Heartland by 2051."
      }
    ]
  },
  {
    imgUrl: winda,
    title: "WINDA",
    description: "Digitising industry safety standards and certification",
    client: "Global Wind Organisation",
    sector: "Raising Standards",
    to: "/winda",
    content: [
      {
        title: "Who is the client?",
        copy:
          "Global Wind Organisation (GWO) is a non-profit body founded by leading wind turbine manufacturers and operators to create a safer and more productive workforce whilst protecting the environment through the growth of renewable energy."
      },
      {
        title: "What was their challenge?",
        copy:
          "The wind industry globally has seen rapid expansion in the drive for renewable energy. GWO manages health and safety training standards and certification so that turbines are safely installed by skilled staff. Exponential growth in the training areas covered, certificates issued and number of training providers had become constrained by manual processes and GWO wished to exploit digital technologies to manage certification."
      },
      {
        title: "How did we solve it?",
        copy:
          "We drew on our experience of working with RenewableUK since 2012 on developing and supporting a health and safety incident management tool. Working alongside GWO, we created the initial WINDA application, which was launched in October 2016. We have supported the application since then, iteratively developing and improving it for the benefit of GWO and its members."
      },
      {
        title: "What was the outcome?",
        copy:
          "By the end of 2017, more than 44,000 individual technicians from 135 countries had been certified to the Basic Safety Training (BST) standard. By June 2018, over 250 training sites had been opened by GWO Certified Training Providers around the world. Without digitised management, this simply would not have been feasible."
      }
    ]
  },
  {
    imgUrl: adept,
    title: "ADEPT",
    description: "Achieving a step-change in public-sector innovation",
    client:
      "Association of Directors of Environment, Planning and Transport (ADEPT)",
    sector: "Understanding Impact",
    to: "/adept",
    content: [
      {
        title: "Who is the client?",
        copy:
          "ADEPT represents place directors from county, unitary and metropolitan authorities, along with Local Enterprise Partnerships and corporate partners. Its members focus on creating more resilient communities, economies and infrastructure to unlock economic success."
      },
      {
        title: "What was their challenge?",
        copy:
          "ADEPT wanted to achieve a step change in innovation in the local roads sector across three areas: the application of technology, the use of ‘big data’ and the sharing of knowledge among its membership. ADEPT and its commercial partners recognised that, given the rate of change in the transport sector, there is an imperative to embrace new thinking and technology."
      },
      {
        title: "How did we solve it?",
        copy:
          "As well as helping to develop outline business cases for the three areas, we developed a competition for Smart Local Roads and Live Labs where bidders pitch for Department for Transport (DfT) funds via a Dragons’ Den. In Getting to Grips with Data we assess how collaborative data platforms could unlock data applications at scale for local roads. Finally, our outline structure for a knowledge hub envisages using AI to improve information discovery and sharing for ADEPT members."
      },
      {
        title: "What was the outcome?",
        copy:
          "Our work is already helping ADEPT make a difference to communities. For example, through the Live Labs project we have helped secure £25 million from the DfT to implement technology at scale in areas beyond the usual city environments."
      }
    ]
  }
];
