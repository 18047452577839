import "react-app-polyfill/ie11";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Loading from "./components/Loading/Loading";
import createRoutes from "./routes";

const routes = createRoutes();

ReactDOM.render(
  <Suspense fallback={<Loading></Loading>}>{routes}</Suspense>,
  document.getElementById("root")
);
