import React from "react";

import "./Loading.css";

const Loading = () => {
  return (
    <div className="box">
      <div className="L">L</div>
      <div className="O">O</div>
      <div className="A">A</div>
      <div className="D">D</div>
      <div className="I">I</div>
      <div className="N">N</div>
      <div className="G">G</div>
      <div className="underline"></div>
    </div>
  );
};

export default Loading;
