import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./index.css";

import App from "./components/./App/App";
import ScrollToTop from "./ScrollTop.js";
import Wip from "./components/WipPage/Wip";

import { cases } from "./props/cases";

const CaseStudies = lazy(() => import("./components/CaseStudies/CaseStudies"));
const NotFound = lazy(() => import("./components/NotFound/NotFound"));
const Case = lazy(() => import("./components/Case/Case"));
const Lattice = lazy(() => import("./components/Lattice/Lattice"));

const createRoutes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={App} />

        <Route exact path="/lattice" render={() => <Lattice />} />

        <Route exact path="/case-studies" render={() => <CaseStudies />} />
        <Route
          exact
          path={cases[0].to}
          render={() => <Case case={cases[0]} />}
        />
        <Route
          exact
          path={cases[1].to}
          render={() => <Case case={cases[1]} />}
        />
        <Route
          exact
          path={cases[2].to}
          render={() => <Case case={cases[2]} />}
        />
        <Route
          exact
          path={cases[3].to}
          render={() => <Case case={cases[3]} />}
        />
        <Route
          exact
          path={cases[4].to}
          render={() => <Case case={cases[4]} />}
        />
        <Route
          exact
          path={cases[5].to}
          render={() => <Case case={cases[5]} />}
        />
        <Route
          exact
          path={cases[6].to}
          render={() => <Case case={cases[6]} />}
        />
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default createRoutes;
