import React, { Component } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import "./Wip.scss";

class Wip extends Component {
  render() {
    return (
      <div>
        <div className="object">
          <div className="object-rope"></div>
          <div className="object-shape">
            Coming <span className="soon">Soon</span>
          </div>
        </div>
        <div className="cover">
          <div className="logo">
            <h1 className="logo">Lattice&trade; Platform</h1>
            <p className="text">Work in Progress...</p>
            <div className="btn-group">
              <NavLink
                to={"/case-studies"}
                className="btn-secondary"
                href="mailto:uk-team@wspdigital.co.uk?subject=Smart Consulting Contact Form&body=Dear Smart Consulting,"
              >
                &#x2190; Back to Case Studies
              </NavLink>
              <a
                className="btn-secondary"
                href="mailto:uk-team@wspdigital.co.uk?subject=Smart Consulting Contact Form&body=Dear Smart Consulting,"
              >
                Contact Us!
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Wip;
