/* React group npm packages */
import React, { Component, lazy, Suspense } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

/* App imports */
import Loading from "../Loading/Loading";

/* swtyling and the rest */
import "./App.scss";
import { cases } from "../../props/cases";

/* Dynamic imports - lazy loading */
const About = lazy(() => import("../About/About"));
const Automation = lazy(() => import("../Automation/Automation"));
const CardContainer = lazy(() => import("../CardContainer/CardContainer"));
const ContactInfo = lazy(() => import("../ContactInfo/ContactInfo"));
const Design = lazy(() => import("../Design/Design"));
const Footer = lazy(() => import("../Footer/Footer"));
const Magicians = lazy(() => import("../Magicians/Magicians"));
const Maps = lazy(() => import("../Maps/Maps"));
const NavBar = lazy(() => import(".././NavBar/NavBar"));
const Scalability = lazy(() => import("../Scalability/Scalability"));

const selectedCases = cases.slice(0, 3);

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loading />}>
        <div className="flex-container">
          <section>
            <NavBar />
          </section>

          <section>
            <About />
          </section>

          <section>
            <Scalability />
          </section>

          <section>
            <Automation />
          </section>

          <section>
            <Design />
          </section>

          <section>
            <Magicians />
          </section>

          <section className="sc-card-section">
            <div className="sc-card-section" name="cards" id="cards">
              <div className="sc-cases-container">
                <div className="sc-card-section--header">
                  <p className="sc-card-section-header__title">Featured Work</p>
                  <NavLink to="/case-studies" className="btn-primary">
                    View All &#8594;
                  </NavLink>
                </div>

                <CardContainer cards={selectedCases} />
                <ContactInfo />
                <div className="sc-maps">
                  <Maps />
                </div>
              </div>
            </div>
          </section>

          <section>
            <Footer />
          </section>
        </div>
      </Suspense>
    );
  }
}

export default App;
